import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import * as Sentry from "@sentry/browser";

export default class extends Controller {
  static targets = [
    "List", "ListItem", "LoadingSpinner", "EmptyMessage", "HideOnEmpty", "RowTotalPrice", "SummaryOrderItem", "SummarySubtotalAmount",
    "FullLoadingSpinner", "SubmitButton", "EarlySeasonPromoNotice"
  ]
  static values = {
    trackConversion: Boolean,
    trackConversionEventId: String
  }

  connect() {
    this.quantityIsUpdating = false
    if (this.trackConversionValue) {
      this.trackConversionEvent()
    }
  }

  preventDefault(e) {
    e.preventDefault()
  }

  trackConversionEvent() {
    if (window.dataLayer) {
      window.dataLayer.push({
        'event': 'AddToCart',
        'conversionValue': 3.50,
        'conversionEventId': this.trackConversionEventIdValue
      })
    }
  }

  async updateHighPurityNonGmoOrderItem(responseJson) {
    const hasHighPurityNonGmo = responseJson.hasHighPurityNonGmo
    if (hasHighPurityNonGmo) {
      const highPurityNonGmoOrderItem = this.SummaryOrderItemTargets.find((target) => target.dataset.orderItemId == responseJson.highPurityNonGmoData.order_item_id)
      if (highPurityNonGmoOrderItem) {
        const highPurityNonGmoOrderItemQuantity = highPurityNonGmoOrderItem.querySelector("[data-quantity]")
        highPurityNonGmoOrderItemQuantity.innerHTML = responseJson.highPurityNonGmoData.quantity
        const highPurityNonGmoOrderItemSubtotal = highPurityNonGmoOrderItem.querySelector("[data-order-item-subtotal]")
        highPurityNonGmoOrderItemSubtotal.innerHTML = '$' + parseFloat(responseJson.highPurityNonGmoData.subtotal).toFixed(2)

        const highPurityNonGmoCartListItem = this.ListItemTargets.find((target) => target.dataset.listItemId == responseJson.highPurityNonGmoData.order_item_id)
        const highPurityNonGmoCartListItemItemQuantity = highPurityNonGmoCartListItem.querySelector("[name='order_item[quantity]']")
        highPurityNonGmoCartListItemItemQuantity.value = responseJson.highPurityNonGmoData.quantity

        this.RowTotalPriceTargets.find((target) => target.dataset.id == responseJson.highPurityNonGmoData.order_item_id).innerHTML = '$' + parseFloat(responseJson.highPurityNonGmoData.subtotal).toFixed(2)
      }
    }
  }

  async updateOrderItemQuantity(e) {
    const form = e.target.form

    const request = new FetchRequest(form.method, form.action, { body: new FormData(form), responseKind: 'json' })

    this.showLoadingSpinner()
    const response = await request.perform()

    if (response.ok) {
      this.hideLoadingSpinner()
    }
  }

  async onDeleteClick(e) {
    e.preventDefault()

    const orderItemId = e.currentTarget.dataset.id
    const title = e.currentTarget.dataset.title

    if (confirm(`Are you sure you want to delete '${title}' from your cart?`)) {
      this.showLoadingSpinner()

      const request = new FetchRequest("DELETE", `/order_items/${orderItemId}`, {  responseKind: 'json' })
      await request.perform()
    }
  }

  showLoadingSpinner() {
    this.ListTarget.classList.add("hidden")
    this.LoadingSpinnerTarget.classList.remove("hidden")
  }

  hideLoadingSpinner() {
    this.ListTarget.classList.remove("hidden")
    this.LoadingSpinnerTarget.classList.add("hidden")
  }

  // onPromoSelect() {
  //   this.FullLoadingSpinnerTarget.classList.remove("hidden")
  // }

  enableSubmitButton() {
    this.SubmitButtonTarget.disabled = false
    // this.EarlySeasonPromoNoticeTarget.classList.add('hidden')
  }

}