import { Controller } from "@hotwired/stimulus"
import { create as createNoUiSlider } from 'nouislider'
import { enter, leave } from 'el-transition'
import Cookies from 'js-cookie'
import { Turbo } from "@hotwired/turbo-rails"

import "nouislider/src/nouislider.less"

export default class extends Controller {
  static values = {
    rmLowest: Number,
    rmHighest: Number,
  }

  static targets = [
    "HybridsContainer", "ListingContainer", "ListingMain", "ListingBulletsContainer", "ListingSecondary",
    "ClearFiltersButton", "ClearFiltersButtonFilterBar", "ResultsCount",
    "FiltersContainer", "FilterToggleText", "FilterToggleOpenCaret", "FilterToggleCloseCaret",
    "ZipcodeInput", "ZipcodeError", "FilterCheckboxInventoryStatusAvailable",
    "FilterRmRange", "RmRangeText",
    "FilterContainerCategoryPerformer", "FilterContainerCategoryAdaptable", "FilterContainerCategoryToughAsNails",
    "FilterContainerPurpose", "FilterContainerDroughtTolerance", "FilterContainerSoilType",
    "FilterContainerDiseaseToleranceGosssWilt", "FilterContainerDiseaseToleranceGrayLeafSpot", "FilterContainerDiseaseToleranceNorthernLeafBlight", "FilterContainerDiseaseToleranceStalkAnthracnose",
    "FilterCheckboxCategoryPerformer", "FilterCheckboxCategoryAdaptable", "FilterCheckboxCategoryToughAsNails",
    "FilterRadioPurposeSilage", "FilterRadioPurposeGrain", "FilterRadioPurposeFoodPlot", "FilterRadioPurposeFoodGrade", "FilterRadioDroughtToleranceDryland", "FilterRadioDroughtToleranceIrrigated", "FilterRadioSoilTypeProductive",  "FilterRadioSoilTypeMarginal", "FilterCheckboxDiseaseToleranceGosssWilt", "FilterCheckboxDiseaseToleranceGrayLeafSpot", "FilterCheckboxDiseaseToleranceNorthernLeafBlight", "FilterCheckboxDiseaseToleranceStalkAnthracnose",
    "QuantityInput", "AddToCartButton", "ModalContent", "ModalContentContainer",
    "AddToCartModal", "AddToCartModalBgOverlay", "AddToCartModalContent", "AddToCartModalQuantityInput", "AddToCartModalSubmitButton",
    "FoodPlotLabel", "FoodGradeLabel"
  ]

  hybridFinderController() {
    return this.application.getControllerForElementAndIdentifier(document.querySelector("[data-controller='hybrid-finder']"), 'hybrid-finder')
  }

  mapController() {
    return this.application.getControllerForElementAndIdentifier(document.querySelector("[data-controller='map']"), 'map')
  }

  initialize() {
    this.listings = []
    this.listingMatches = []
    this.zipcodeMatchingListingData = []
    this.openListings = []
    this.mobileFiltersExpanded = false

    this.defaultRmRangeLow = this.rmLowestValue
    this.defaultRmRangeHigh = this.rmHighestValue
    this.rmRangeLow = this.rmLowestValue
    this.rmRangeHigh = this.rmHighestValue
    this.categories = []
    this.purpose = null
    this.droughtTolerance = null
    this.soilType = null
    this.diseaseTolerances = []
    this.selectedZipcode = null
    this.inventoryStatusAvailableOnly = false

    this.ListingContainerTargets.map((listingContainer) => {
      var listingObject = JSON.parse(listingContainer.dataset.json)

      this.listings.push(listingObject)
      this.listingMatches.push(listingObject)
    })

    this.lastServerFilterParams = {};
    this.serverFilteredListings = [];

    this.isComparisonMode = false;
  }

  async connect() {
    this.element[this.identifier] = this;

    this.checkForComparisonsInUrl();

    // Initialize slider if not in comparison mode
    if (!this.isComparisonMode && this.hasFilterRmRangeTarget) {
      this.initRmSlider();
    }

    // Determine if we should show a modal based on URL
    const pathParts = window.location.pathname.split('/');
    this.isShowingModalFromUrl = pathParts[1] === 'hybrids' && pathParts.length > 2;
    const savedFilters = Cookies.get('hybrids_filters');

    if (savedFilters) {
      await this.loadFiltersFromCookie();
    } else {
      await this.initializeFromUrlParams();
    }

    await this.initializeAfterFiltersLoaded();

    this.adjustBulletsTextSize();

    window.addEventListener('load', () => {
      this.setCustomerZipcode();
      this.adjustBulletsTextSize();
    });

    this.compare = this.compare.bind(this);
    this.cancelCompare = this.cancelCompare.bind(this);
    document.addEventListener("compare", this.compare);
    document.addEventListener("cancelCompare", this.cancelCompare);
    window.addEventListener('popstate', this.onPopState.bind(this));
  }

  async initializeAfterFiltersLoaded() {
    // Only show popup if we haven't navigated away
    if (this.isShowingModalFromUrl) {
      await this.showPopupViaUrl();
    }
  }

  disconnect() {
    document.removeEventListener("compare", this.compare);
    document.removeEventListener("cancelCompare", this.cancelCompare);
    window.removeEventListener('popstate', this.onPopState.bind(this));
  }

  async onPopState(event) {
    // Prevent double processing
    if (this.isHandlingPopState) return;
    this.isHandlingPopState = true;

    try {
      const state = event.state;
      const params = new URLSearchParams(window.location.search);
      const compareIdsParam = params.get("compare_ids");
      const isNowComparisonMode = Boolean(compareIdsParam);
      const wasComparisonMode = this.isComparisonMode;

      // Handle comparison mode changes
      if (isNowComparisonMode) {
        await this.loadComparisonView(compareIdsParam.split(","));
      } else if (wasComparisonMode) {
        await this.loadStandardView({ exitingComparison: true });
      } else {
        // Handle modal navigation
        if (state?.type === 'hybrid-detail') {
          try {
            const hybridId = window.location.pathname.split('/').pop();
            const modalContainer = await this.waitForElement(`[data-modal-listing-id='${hybridId.toLowerCase()}']`);
            if (modalContainer) {
              const modalController = this.application.getControllerForElementAndIdentifier(modalContainer, "modal");
              if (modalController) {
                modalController.openModal();
              }
            }
          } catch (error) {
            console.warn('Modal element not found:', error);
            // Fallback behavior - redirect to the hybrids listing
            window.history.replaceState(
              { type: 'hybrid-listing' },
              'Hybrids - Hybrid85',
              '/hybrids'
            );
          }
        } else if (state?.type === 'hybrid-listing') {
          // Close any open modals
          const openModals = document.querySelectorAll('[data-controller="modal"].modal-open');
          openModals.forEach(modal => {
            const modalController = this.application.getControllerForElementAndIdentifier(modal, "modal");
            if (modalController) {
              modalController.closeModal();
            }
          });

          // Use Turbo's built-in navigation for regular filter changes
          if (window.location.search) {
            await Turbo.visit(window.location.href, { action: "replace" });
          }
        }
      }

      this.isComparisonMode = isNowComparisonMode;
    } catch (error) {
      console.error('Error handling popstate:', error);
      // Ensure we have a valid state even if something fails
      window.history.replaceState(
        { type: 'hybrid-listing' },
        'Hybrids - Hybrid85',
        '/hybrids'
      );
    } finally {
      // Always reset the handling flag
      this.isHandlingPopState = false;
    }
  }

  checkForComparisonsInUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    const compareIdsParam = urlParams.get("compare_ids");
    if (compareIdsParam) {
      this.isComparisonMode = true;
    }
  }

  async loadComparisonView(compareIds) {
    const params = new URLSearchParams(window.location.search);
    params.set("compare_ids", compareIds.join(","));
    const response = await fetch(`/hybrids/filter?${params.toString()}`, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    });
    const html = await response.text();
    Turbo.renderStreamMessage(html);
  }

  async loadStandardView(options = {}) {
    const params = new URLSearchParams(window.location.search);
    if (options.exitingComparison) {
      params.set('exiting_comparison', 'true');
    }

    const response = await fetch(`/hybrids/filter?${params.toString()}`, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    });
    const html = await response.text();
    Turbo.renderStreamMessage(html);

    // Wait for the DOM to update
    await new Promise(requestAnimationFrame);

    // Re-initialize filters from URL params
    await this.initializeFromUrlParams();

    // Initialize map if the controller exists
    const mapController = this.mapController();
    if (mapController) {
      mapController.initMap();
    }
  }

  compare(event) {
    const compareIds = event.detail.compareIds;
    this.loadComparisonView(compareIds);
  }

  cancelCompare(event) {
    const params = new URLSearchParams(window.location.search);
    params.delete("compare_ids");
    fetch(`/hybrids/filter?${params.toString()}`, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      });
  }

  onCancelComparisonClick(e) {
    e.preventDefault();
    // Remove compare_ids from URL
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete("compare_ids");
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState({}, "", newUrl);

    this.cancelCompare();
  }

  initRmSlider() {
    // Check if slider is already initialized
    if (this.FilterRmRangeTarget.noUiSlider) {
      this.FilterRmRangeTarget.noUiSlider.destroy();
    }

    createNoUiSlider(this.FilterRmRangeTarget, {
      range: {
        'min': this.defaultRmRangeLow,
        'max': this.defaultRmRangeHigh,
      },
      step: 1,
      start: [this.defaultRmRangeLow, this.defaultRmRangeHigh],
      margin: 1,
      connect: true,
    })

    this.FilterRmRangeTarget.noUiSlider.on('slide', this.onRmSlide.bind(this))
  }

  async initializeFromUrlParams() {
    const params = new URLSearchParams(window.location.search);

    // Don't initialize other filters if in comparison mode
    if (this.isComparisonMode) {
      return;
    }

    // Set zipcode
    if (params.has('zipcode')) {
      this.ZipcodeInputTarget.value = params.get('zipcode');
      this.selectedZipcode = params.get('zipcode');
    }

    // Set availability
    if (params.has('availability')) {
      this.FilterCheckboxInventoryStatusAvailableTarget.checked = params.get('availability') === 'true';
      this.inventoryStatusAvailableOnly = params.get('availability') === 'true';
    }

    // Set maturity range
    if (params.has('min_days') && params.has('max_days')) {
      const minDays = parseInt(params.get('min_days'));
      const maxDays = parseInt(params.get('max_days'));
      this.FilterRmRangeTarget.noUiSlider.set([minDays, maxDays]);
      this.rmRangeLow = minDays;
      this.rmRangeHigh = maxDays;
    }

    // Set purpose
    if (params.has('purpose')) {
      const purpose = params.get('purpose');
      this[`FilterRadioPurpose${purpose.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('')}Target`].checked = true;
      this.purpose = purpose;
    }

    // Set drought tolerance
    if (params.has('drought_tolerance')) {
      const droughtTolerance = params.get('drought_tolerance');
      this[`FilterRadioDroughtTolerance${droughtTolerance.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('')}Target`].checked = true;
      this.droughtTolerance = droughtTolerance;
    }

    // Set soil type
    if (params.has('soil_type')) {
      const soilType = params.get('soil_type');
      this[`FilterRadioSoilType${soilType.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('')}Target`].checked = true;
      this.soilType = soilType;
    }

    // Set Category Performer
    if (params.has('category')) {
      const categories = params.getAll('category').flatMap(cat => cat.split(',').map(c => c.trim()));
      categories.forEach((category) => {
        this[`FilterCheckboxCategory${category.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('')}Target`].checked = true;
      });
      this.categories = categories;
    }

    // Set Disease Tolerance
    if (params.has('disease_tolerance')) {
      const diseaseTolerances = params.getAll('disease_tolerance').flatMap(dis => dis.split(',').map(d => d.trim()));
      diseaseTolerances.forEach((diseaseTolerance) => {
        this[`FilterCheckboxDiseaseTolerance${diseaseTolerance.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('').replace("'", "")}Target`].checked = true;
      });
    }

    console.log('params in initializeFromUrlParams', params.keys());
    // Trigger initial filter if any params exist
    if ([...params.keys()].length > 0) {
      this.submitFilters();
    }
  }

  // Modified method to update URL with current filter state
  updateUrl() {
    const params = new URLSearchParams();

    if (this.selectedZipcode) {
      params.set('zipcode', this.selectedZipcode);
    }

    if (this.inventoryStatusAvailableOnly) {
      params.set('availability', 'true');
    }

    if (this.rmRangeLow !== this.defaultRmRangeLow || this.rmRangeHigh !== this.defaultRmRangeHigh) {
      params.set('min_days', this.rmRangeLow);
      params.set('max_days', this.rmRangeHigh);
    }

    if (this.purpose) {
      params.set('purpose', this.purpose.replace(/\s+/g, '_').toLowerCase());
    }

    if (this.droughtTolerance) {
      params.set('drought_tolerance', this.droughtTolerance.replace(/\s+/g, '_').toLowerCase());
    }

    if (this.soilType) {
      params.set('soil_type', this.soilType.replace(/\s+/g, '_').toLowerCase());
    }

    if (this.categories.length > 0) {
      params.set('category', this.categories.join(','));
    }

    if (Array.isArray(this.diseaseTolerances) && this.diseaseTolerances.length > 0) {
      params.set('disease_tolerance', this.diseaseTolerances.join(','));
    }

    const newUrl = `${window.location.pathname}${params.toString() ? '?' + params.toString() : ''}`;
    window.history.replaceState({}, '', newUrl);
  }

  async showPopupViaUrl() {
    try {
      const pathParts = window.location.pathname.split('/');
      let urlParam = pathParts[2]; // Get the hybrid name from the URL
      if (!urlParam) return;

      // Handle specific URL parameters if necessary
      const urlMappings = {
        "h87c9": "h87c",
        "h104sc": "h104s",
        "h113c": "h113",
        "h117sc9": "h117sc"
      };

      urlParam = urlMappings[urlParam.toLowerCase()] || urlParam;

      try {
        // Wait for the modal container to be available
        const modalContainer = await this.waitForElement(
          `[data-modal-listing-id='${urlParam.toLowerCase()}']`,
          10000 // Increased timeout for slower connections
        );

        if (modalContainer) {
          const modalController = this.application.getControllerForElementAndIdentifier(modalContainer, "modal");
          if (modalController) {
            modalController.openModal();
            this.onOpenModal({ currentTarget: modalContainer, listingTitle: urlParam.toLowerCase() });
          } else {
            throw new Error('Modal controller not found for modalContainer');
          }
        } else {
          throw new Error('No modalContainer found for URL param: ' + urlParam);
        }
      } catch (modalError) {
        console.warn('Modal loading error:', modalError);
        // Fallback behavior - redirect to the hybrids listing
        window.history.replaceState(
          { type: 'hybrid-listing' },
          'Hybrids - Hybrid85',
          '/hybrids'
        );
      }
    } catch (error) {
      console.error('Error in showPopupViaUrl:', error);
      // Ensure we're in a valid state
      window.history.replaceState(
        { type: 'hybrid-listing' },
        'Hybrids - Hybrid85',
        '/hybrids'
      );
    }
  }

  setCustomerZipcode() {
    if (Cookies.get('admin_selected_customer')) {
      const customer = JSON.parse(Cookies.get('admin_selected_customer'));
      if (this.hasZipcodeInputTarget) {
        this.ZipcodeInputTarget.value = customer.most_recent_order.shipping_zipcode;
        this.ZipcodeInputTarget.dispatchEvent(new Event('input'));
      }
    }
  }

  adjustTopMarginForNavbar() {
    this.ModalContentTargets.forEach((modalContent) => {
      modalContent.classList.add("sm:!mt-18", "xl:!mt-12")
    })
    this.ModalContentContainerTargets.forEach((modalContentContainer) => {
      modalContentContainer.classList.add("!mt-12", "sm:!mt-0")
    })
  }

  adjustBulletsTextSize() {
    this.ListingBulletsContainerTargets.forEach((bulletContainer) => {
      let textRectHeights = []
      const textTargets = bulletContainer.querySelectorAll("li.list-disc")
      textTargets.forEach((textTarget) => {
        textRectHeights.push(textTarget.getBoundingClientRect().height)
      })
      const lineHeight = parseInt(window.getComputedStyle(textTargets[0]).getPropertyValue("line-height"), 10)
      const numLines = textRectHeights.reduce((accumulator, curr) => accumulator + curr) / lineHeight

      if (numLines >= 6) {
        bulletContainer.classList.add("text-2sm", "sm:text-xs")
      }
    })
  }

  onAddToCartClick(e) {
    const listingId = e.currentTarget.dataset.listingId;
    const quantityInput = this.QuantityInputTargets.find((input) => input.dataset.listingId == listingId);
    const quantityValue = parseInt(quantityInput.value);

    if (!Number.isInteger(quantityValue) || quantityValue <= 0) {
      e.stopPropagation();
      e.preventDefault();
      quantityInput.classList.add("border-red-500", "border-4");
      return;
    }
  }

  onQuantityInput(e) {
    if (e.target.classList.contains("border-red-500")) {
      e.target.classList.remove("border-red-500", "border-4")
    }
  }

  onCardCallForInventoryClick(e) {
    e.stopPropagation()

    const phoneCallUrl = e.currentTarget.dataset.phoneCallUrl
    const link = document.createElement('a')

    link.setAttribute('href', phoneCallUrl)
    link.setAttribute('target', '_blank')
    link.setAttribute('rel', 'noopener noreferrer')

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  onRmSlide(values) {
    values = values.map((value) => parseInt(value));
    this.rmRangeLow = values[0];
    this.rmRangeHigh = values[1];
    this.RmRangeTextTarget.innerHTML = `${values[0]} - ${values[1]} days`;

    // Debounce the filter submission for slider
    clearTimeout(this.rmSlideTimeout);
    this.rmSlideTimeout = setTimeout(() => {
      this.submitFilters();
    }, 300);
  }

  onClearFilters() {
    // Reset all filter values
    this.rmRangeLow = this.defaultRmRangeLow;
    this.rmRangeHigh = this.defaultRmRangeHigh;
    this.FilterRmRangeTarget.noUiSlider.set([this.defaultRmRangeLow, this.defaultRmRangeHigh]);
    this.RmRangeTextTarget.innerHTML = `${this.defaultRmRangeLow} - ${this.defaultRmRangeHigh} days`;

    // Reset categories
    this.categories = []
    const categories = ["Performer", "Adaptable", "ToughAsNails"];
    for (const category of categories) {
      const containerTarget = this[`FilterContainerCategory${category}Target`];
      const checkboxTarget = this[`FilterCheckboxCategory${category}Target`];

      if (containerTarget) {
        containerTarget.classList.remove("bg-highlight-blue", "rounded");
      }

      if (checkboxTarget) {
        checkboxTarget.checked = false;
      }
    }

    // Reset purpose
    this.purpose = null;
    this.FilterContainerPurposeTargets.forEach(target => target.classList.remove("bg-highlight-blue", "rounded"));
    ["Silage", "Grain", "FoodPlot", "FoodGrade"].forEach(purpose => {
      this[`FilterRadioPurpose${purpose}Target`].checked = false;
    });

    // Reset drought tolerance
    this.droughtTolerance = null;
    this.FilterContainerDroughtToleranceTargets.forEach(target => target.classList.remove("bg-highlight-blue", "rounded"));
    ["Dryland", "Irrigated"].forEach(tolerance => {
      this[`FilterRadioDroughtTolerance${tolerance}Target`].checked = false;
    });

    // Reset soil type
    this.soilType = null;
    this.FilterContainerSoilTypeTargets.forEach(target => target.classList.remove("bg-highlight-blue", "rounded"));
    ["Productive", "Marginal"].forEach(type => {
      this[`FilterRadioSoilType${type}Target`].checked = false;
    });

    // Reset disease tolerance
    ["Goss's Wilt", "Gray Leaf Spot", "Northern Leaf Blight", "Stalk Anthracnose"].forEach(disease => {
      this.diseaseTolerances = []
      this[`FilterContainerDiseaseTolerance${disease.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join("").replace("'", "")}Target`].classList.remove("bg-highlight-blue", "rounded");
      this[`FilterCheckboxDiseaseTolerance${disease.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join("").replace("'", "")}Target`].checked = false;
    });

    // Reset zipcode and availability
    this.selectedZipcode = null;
    this.inventoryStatusAvailableOnly = false;
    if (this.hasZipcodeInputTarget) {
      this.ZipcodeInputTarget.value = '';
    }
    if (this.hasFilterCheckboxInventoryStatusAvailableTarget) {
      this.FilterCheckboxInventoryStatusAvailableTarget.checked = false;
    }

    // Clear URL parameters and refresh
    window.history.replaceState({}, '', window.location.pathname);
    this.submitFilters();

    if (this.hybridFinderController()) {
      this.hybridFinderController().onClearFilters();
    }

    // Remove the filters cookie
    Cookies.remove('hybrids_filters');
  }

  onOpenModal(e) {
    const isOnHybridFinderPage = this.element.dataset.onHybridFinderPage;

    // Store the current URL with filters, but only if we're not already viewing a hybrid detail
    if (window.location.search) {
      this.previousUrl = window.location.href;
    }

    if (!isOnHybridFinderPage) {
      const listingTitle = e.currentTarget.dataset.urlParam;
      // Use replaceState to add the hybrid detail to URL
      window.history.replaceState(
        { type: 'hybrid-detail', previousUrl: this.previousUrl },
        `${listingTitle} details - Hybrid85`,
        `/hybrids/${listingTitle}`
      );
    }

    const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches
    if (isMobile) {
      this.ListingMainTargets.forEach((mainTarget) => {
        mainTarget.classList.add("!border-0")
      })
    }
  }

  onCloseModal(e) {
    // Get the current state
    const currentState = window.history.state;

    if (currentState?.previousUrl) {
      // filter out the hybrid ID from the previous URL
      // e.g. /hybrids/h87c9?zipcode=80301 becomes /hybrids?zipcode=80301
      const previousUrlWithoutHybridId = currentState?.previousUrl.replace(/\/hybrids\/[^/?]+/, '/hybrids');

      window.history.replaceState(
        { type: 'hybrid-listing' },
        'Hybrids - Hybrid85',
        previousUrlWithoutHybridId
      );
      this.previousUrl = null;
    } else {
      // Default back to the base hybrids page
      window.history.replaceState(
        { type: 'hybrid-listing' },
        'Hybrids - Hybrid85',
        '/hybrids'
      );
    }

    let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches
    if (isMobile) {
      this.ListingMainTargets.forEach((mainTarget) => {
        mainTarget.classList.remove("!border-0")
      })
    }
  }

  onToggleMobileFiltersExpanded(e) {
    this.mobileFiltersExpanded = !this.mobileFiltersExpanded

    if (this.mobileFiltersExpanded) {
      this.FilterToggleOpenCaretTarget.classList.add("hidden")
      this.FilterToggleCloseCaretTarget.classList.remove("hidden")
      this.FiltersContainerTarget.classList.remove("hidden")
      this.FilterToggleTextTarget.innerHTML = "Less"
    } else {
      this.FilterToggleOpenCaretTarget.classList.remove("hidden")
      this.FilterToggleCloseCaretTarget.classList.add("hidden")
      this.FiltersContainerTarget.classList.add("hidden")
      this.FilterToggleTextTarget.innerHTML = "More"
    }
  }

  onZipcodeChange(e) {
    // only trigger search when 5 digits or 5 digits+4
    if (e.target.value == '') {
      this.selectedZipcode = null
      if (this.mapController()) {
        this.mapController().selectedZipcode = null
      }
      this.ZipcodeErrorTarget.classList.add("hidden")
      this.submitFilters();
      return;
    }

    if ((e.target.value.length === 5 && e.target.value.match(/\d{5}/)) ||
        (e.target.value.length === 10 && e.target.value.match(/\d{5}-\d{4}/))) {
      this.selectedZipcode = e.target.value;
      if (this.mapController()) {
        this.mapController().onZipcodeChange(e.target.value);
      }
      this.ZipcodeErrorTarget.classList.add("hidden");
      this.submitFilters();
    } else {
      this.ZipcodeErrorTarget.classList.remove("hidden");
    }
  }

  onAvailabilityStatusClick(e) {
    e.stopPropagation()
    this.inventoryStatusAvailableOnly = e.target.checked;
    this.submitFilters();
    this.displayClearFilters();
  }

  onCategoryClick(e) {
    const category = e.currentTarget.value;
    const targetName = `FilterContainerCategory${category.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('')}Target`

    if (!this.categories.includes(category)) {
      this[targetName].classList.add("bg-highlight-blue", "rounded");
      this.categories.push(category)
    } else {
      this[targetName].classList.remove("bg-highlight-blue", "rounded");
      this.categories = this.categories.filter((c) => c != category)
    }

    this.submitFilters();
    this.displayClearFilters();
  }

  onPurposeClick(e) {
    this.purpose = e.currentTarget.value;

    this.FilterContainerPurposeTargets.forEach((target) => {
      const purposeValue = target.dataset.purposeValue;
      if (purposeValue != this.purpose) {
        target.classList.remove("bg-highlight-blue", "rounded");
      } else {
        target.classList.add("bg-highlight-blue", "rounded");
      }
    });

    if (this.purpose == "FoodPlot") {
      this.FoodPlotLabelTargets.forEach((target) => target.classList.remove("hidden"));
    } else {
      this.FoodPlotLabelTargets.forEach((target) => target.classList.add("hidden"));
    }

    if (this.purpose == "FoodGrade") {
      this.FoodGradeLabelTargets.forEach((target) => target.classList.remove("hidden"));
    } else {
      this.FoodGradeLabelTargets.forEach((target) => target.classList.add("hidden"));
    }

    this.submitFilters();
    this.displayClearFilters();

    if (this.hybridFinderController()) {
      this.hybridFinderController().onFilterClick('purpose', this.purpose);
    }
  }

  onDroughtToleranceClick(e) {
    this.droughtTolerance = e.currentTarget.value;

    this.FilterContainerDroughtToleranceTargets.forEach((target) => {
      const droughtToleranceValue = target.dataset.droughtToleranceValue;
      if (droughtToleranceValue != this.droughtTolerance) {
        target.classList.remove("bg-highlight-blue", "rounded");
      } else {
        target.classList.add("bg-highlight-blue", "rounded");
      }
    });

    this.submitFilters();
    this.displayClearFilters();
  }

  onSoilTypeClick(e) {
    this.soilType = e.currentTarget.value;

    this.FilterContainerSoilTypeTargets.forEach((target) => {
      const soilTypeValue = target.dataset.soilTypeValue;
      if (soilTypeValue != this.soilType) {
        target.classList.remove("bg-highlight-blue", "rounded");
      } else {
        target.classList.add("bg-highlight-blue", "rounded");
      }
    });

    this.submitFilters();
    this.displayClearFilters();
  }

  onDiseaseToleranceClick(e) {
    const diseaseTolerance = e.currentTarget.value;
    const targetName = `FilterContainerDiseaseTolerance${diseaseTolerance.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).replace("'", "")).join('')}Target`
    if (!this.diseaseTolerances.includes(diseaseTolerance)) {
      this.diseaseTolerances.push(diseaseTolerance);
    } else {
      this.diseaseTolerances = this.diseaseTolerances.filter((dt) => dt != diseaseTolerance);
    }

    if (this.diseaseTolerances.includes(diseaseTolerance)) {
      this[targetName].classList.add("bg-highlight-blue", "rounded");
    } else {
      this[targetName].classList.remove("bg-highlight-blue", "rounded");
    }

    this.submitFilters();
    this.displayClearFilters();
  }

  stopPropagation(e) {
    e.stopPropagation()
  }

  async submitFilters(options = {}) {
    const params = new URLSearchParams();

    // Build filter params
    if (this.selectedZipcode) params.set('zipcode', this.selectedZipcode);
    if (this.inventoryStatusAvailableOnly) params.set('availability', 'true');
    if (this.rmRangeLow !== this.defaultRmRangeLow || this.rmRangeHigh !== this.defaultRmRangeHigh) {
      params.set('min_days', this.rmRangeLow);
      params.set('max_days', this.rmRangeHigh);
    }
    if (this.purpose) params.set('purpose', this.purpose);
    if (this.droughtTolerance) params.set('drought_tolerance', this.droughtTolerance);
    if (this.soilType) params.set('soil_type', this.soilType);
    if (this.categories.length > 0) params.set('category', this.categories.join(','));
    if (this.diseaseTolerances.length > 0) params.set('disease_tolerance', this.diseaseTolerances.join(','));

    // Always update the URL, even if params are empty
    this.updateUrl();
    console.log('params in submitFilters', params.toString());
    // Fetch filtered results (or unfiltered if params are empty)
    const response = await fetch(`/hybrids/filter?${params.toString()}`, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
      },
    });

    if (response.ok) {
      const html = await response.text();

      if (options.preserveModalContent) {
        // Store current modal content before updating
        const modalContent = document.querySelector('.modal-open .modal-content')?.innerHTML;

        // Render the stream
        Turbo.renderStreamMessage(html);

        // Restore modal content if it was open
        if (modalContent) {
          const openModal = document.querySelector('.modal-open .modal-content');
          if (openModal) {
            openModal.innerHTML = modalContent;
          }
        }
      } else {
        Turbo.renderStreamMessage(html);
      }
    }

    this.saveFiltersToCookie();
  }

  displayClearFilters() {
    const showClearFilters = (
      this.rmRangeLow != this.defaultRmRangeLow ||
      this.rmRangeHigh != this.defaultRmRangeHigh ||
      this.categories.length > 0 ||
      this.diseaseTolerances.length > 0 ||
      this.purpose !== null ||
      this.droughtTolerance !== null ||
      this.soilType !== null ||
      this.selectedZipcode != null ||
      this.inventoryStatusAvailableOnly == true
    );

    if (this.hasClearFiltersButtonTarget) {
      if (showClearFilters) {
        this.ClearFiltersButtonTarget.classList.remove("hidden");
        this.ClearFiltersButtonTarget.classList.add("flex");
        this.ClearFiltersButtonFilterBarTargets.forEach((target) => {
          target.classList.remove("hidden");
          target.classList.add("sm:flex");
        });
      } else {
        this.ClearFiltersButtonTarget.classList.add("hidden");
        this.ClearFiltersButtonTarget.classList.remove("sm:flex");
        this.ClearFiltersButtonFilterBarTargets.forEach((target) => {
          target.classList.remove("sm:flex");
          target.classList.add("hidden");
        });
      }
    }
  }

  onDownloadSpecPdf() {
    if (window.dataLayer) {
      window.dataLayer.push({
        'event': 'download product pdf',
        'conversionValue': 15.00
      })
    }
    // if (window.gtag) {
    //   gtag('event', 'download product pdf', { event_category: 'Lead', currency: 'USD', value: 15.00 })
    //   gtag('event', 'conversion', {'send_to': 'AW-779913037/x5uCCNiwg40BEM2O8vMC', 'currency': 'USD', 'value': 15.00})
    // }
    // if (window.fbq) {
    //   fbq('trackCustom', 'download product pdf', { currency: 'USD', value: 15.00 })
    // }
    // if (window.uetq) {
    //   window.uetq.push('event', 'other', {"revenue_value":15.00,"currency":"USD"});
    // }
  }

  hideAddToCartButtons() {
    this.AddToCartButtonTargets.forEach(button => {
      button.classList.add('hidden')
    })
  }

  showAddToCartButtons() {
    this.AddToCartButtonTargets.forEach(button => {
      button.classList.remove('hidden')
    })
  }

  async loadFiltersFromCookie() {
    const savedFilters = Cookies.get('hybrids_filters');
    if (savedFilters) {
      const filters = JSON.parse(savedFilters);

      // Apply saved filters
      this.selectedZipcode = filters.selectedZipcode || null;
      this.inventoryStatusAvailableOnly = filters.inventoryStatusAvailableOnly || false;
      this.rmRangeLow = filters.rmRangeLow || this.defaultRmRangeLow;
      this.rmRangeHigh = filters.rmRangeHigh || this.defaultRmRangeHigh;
      this.purpose = filters.purpose || null;
      this.droughtTolerance = filters.droughtTolerance || null;
      this.soilType = filters.soilType || null;
      this.categories = filters.categories || [];
      this.diseaseTolerances = filters.diseaseTolerances || [];

      // Update the UI to reflect the loaded filters (which also submits the filters)
      this.updateFilterUI();
    }
  }

  updateFilterUI() {
    // Update zipcode input
    if (this.selectedZipcode && this.hasZipcodeInputTarget) {
      this.ZipcodeInputTarget.value = this.selectedZipcode;

      // Notify the map controller about the zipcode change
      if (this.mapController()) {
        this.mapController().onZipcodeChange(this.selectedZipcode);
      }
    }

    // Update availability checkbox
    if (this.hasFilterCheckboxInventoryStatusAvailableTarget) {
      this.FilterCheckboxInventoryStatusAvailableTarget.checked = this.inventoryStatusAvailableOnly;
    }

    // Update RM range slider
    if (this.hasFilterRmRangeTarget) {
      setTimeout(() => {
        this.FilterRmRangeTarget.noUiSlider.set([this.rmRangeLow, this.rmRangeHigh]);
        this.RmRangeTextTarget.innerHTML = `${this.rmRangeLow} - ${this.rmRangeHigh} days`;
      }, 250)
    }

    // Update purpose
    if (this.purpose) {
      const purposeTargetName = `FilterRadioPurpose${this.purpose.charAt(0).toUpperCase() + this.purpose.slice(1)}Target`;
      if (this[`has${purposeTargetName}`]) {
        this[purposeTargetName].checked = true;
        this.onPurposeClick({ currentTarget: this[purposeTargetName] });
      }
    }

    // Update drought tolerance
    if (this.droughtTolerance) {
      const droughtToleranceTargetName = `FilterRadioDroughtTolerance${this.droughtTolerance.charAt(0).toUpperCase() + this.droughtTolerance.slice(1)}Target`;
      if (this[`has${droughtToleranceTargetName}`]) {
        this[droughtToleranceTargetName].checked = true;
        this.onDroughtToleranceClick({ currentTarget: this[droughtToleranceTargetName] });
      }
    }

    // Update soil type
    if (this.soilType) {
      const soilTypeTargetName = `FilterRadioSoilType${this.soilType.charAt(0).toUpperCase() + this.soilType.slice(1)}Target`;
      if (this[`has${soilTypeTargetName}`]) {
        this[soilTypeTargetName].checked = true;
        this.onSoilTypeClick({ currentTarget: this[soilTypeTargetName] });
      }
    }

    // Update categories
    this.categories.forEach(category => {
      const categoryTargetName = `FilterCheckboxCategory${category.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('')}Target`;
      if (this[`has${categoryTargetName}`]) {
        this[categoryTargetName].checked = true;
        this.onCategoryClick({ currentTarget: this[categoryTargetName] });
      }
    });

    // Update disease tolerances
    this.diseaseTolerances.forEach(diseaseTolerance => {
      const diseaseToleranceTargetName = `FilterCheckboxDiseaseTolerance${diseaseTolerance.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).replace("'", "")).join('')}Target`;
      if (this[`has${diseaseToleranceTargetName}`]) {
        this[diseaseToleranceTargetName].checked = true;
        this.onDiseaseToleranceClick({ currentTarget: this[diseaseToleranceTargetName] });
      }
    });
  }

  saveFiltersToCookie() {
    const filters = {
      selectedZipcode: this.selectedZipcode,
      inventoryStatusAvailableOnly: this.inventoryStatusAvailableOnly,
      rmRangeLow: this.rmRangeLow,
      rmRangeHigh: this.rmRangeHigh,
      purpose: this.purpose,
      droughtTolerance: this.droughtTolerance,
      soilType: this.soilType,
      categories: this.categories,
      diseaseTolerances: this.diseaseTolerances,
    };

    Cookies.set('hybrids_filters', JSON.stringify(filters), { expires: 90 }); // Expires in 90 days
  }

  // Helper method to get the current filters
  getCurrentFilters() {
    return {
      selectedZipcode: this.selectedZipcode,
      inventoryStatusAvailableOnly: this.inventoryStatusAvailableOnly,
      rmRangeLow: this.rmRangeLow,
      rmRangeHigh: this.rmRangeHigh,
      purpose: this.purpose,
      droughtTolerance: this.droughtTolerance,
      soilType: this.soilType,
      categories: this.categories,
      diseaseTolerances: this.diseaseTolerances,
    };
  }

  waitForElement(selector, timeout = 5000) {
    return new Promise((resolve, reject) => {
      try {
        const element = document.querySelector(selector);
        if (element) {
          resolve(element);
          return;
        }

        const observer = new MutationObserver((mutations, obs) => {
          const element = document.querySelector(selector);
          if (element) {
            obs.disconnect();
            resolve(element);
          }
        });

        observer.observe(document.body, {
          childList: true,
          subtree: true,
          attributes: true
        });

        // Set timeout to cleanup observer
        setTimeout(() => {
          observer.disconnect();
          reject(new Error(`Element ${selector} not found within ${timeout}ms`));
        }, timeout);
      } catch (error) {
        reject(new Error(`Error in waitForElement: ${error.message}`));
      }
    });
  }

}