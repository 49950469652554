import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import * as Sentry from "@sentry/browser"

export default class extends Controller {
  static targets = [
    "ContentContainer",
    "PendingResultsContainer", "ShippingResultsContainer", "LookupFailedContainer",
    "SummarySubtotal", "SummaryShipping", "SummaryTotal",
    "RatePerUnitBeforeDiscount", "RatePerUnit", "RateAmount"
  ]

  get splitShippingController() {
    return this.application.controllers.find((controller) => controller.identifier == 'split-shipment')
  }

  connect() {
    this.shippingRequestAttemptCount = 0
    this.doneWithoutResultsCount = 0

    if (this.hasShippingResultsContainerTarget || this.hasLookupFailedContainerTarget) {
      this.trackAnalyticsEvent()
    }
    if (this.hasPendingResultsContainerTarget) {
      this.startPolling()
    }
  }

  onShippingSelectionChange(e) {
    const radioRateElem = this.RateAmountTargets.find((target) => target.dataset.index == e.target.dataset.index)
    let radioRate = radioRateElem.innerHTML
    radioRate = radioRate.replace("$", "").replace(",", "")
    radioRate = parseFloat(radioRate)
    if (radioRateElem.dataset.discountPercent && Number(radioRateElem.dataset.discountPercent) > 0) {
      radioRate = radioRate * (1 - Number(radioRateElem.dataset.discountPercent) / 100)
    }
    this.updateOrderSummaryForShipping(radioRate)

    if (this.splitShippingController) {
      this.splitShippingController.updateSelectedRate(radioRate)
    }
  }

  trackAnalyticsEvent() {
    if (window.dataLayer) {
      window.dataLayer.push({
        'event': 'RequestedShipping',
        'conversionValue': 15.00,
        'conversionEventId': this.element.dataset.conversionEventId
      })
    }
  }

  startPolling() {
    if (this.PendingResultsContainerTarget.dataset.status == 'pending') {
      setTimeout(() => this.pollForStatusUpdate(this.shippingRequestAttemptCount), 5000)
    }
  }

  async pollForStatusUpdate(count) {
    const attemptCount = count + 1

    const request = new FetchRequest("GET", `/shipping_requests/${this.element.dataset.id}?from_js=true`, { responseKind: 'json' })
    const response = await request.perform()

    if (response.ok) {
      const responseJson = await response.json
      if (responseJson.status == 'pending') {
        if (attemptCount > 24) {
          this.handleShippingLookupError()
        } else {
          setTimeout(() => this.pollForStatusUpdate(attemptCount), 5000)
        }
      } else if (responseJson.status == 'done') {
        if (responseJson.results && responseJson.results.length > 0) {
          this.trackAnalyticsEvent()
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        } else {
          this.doneWithoutResultsCount++

          if (this.doneWithoutResultsCount < 3) {
            setTimeout(() => this.pollForStatusUpdate(attemptCount), 5000)
          } else {
            this.trackAnalyticsEvent()
            setTimeout(() => {
              window.location.reload()
            }, 2000)
          }
        }
      } else if (responseJson.status == 'failed') {
        this.handleShippingLookupError()
      }
    } else {
      const error = await response.json
      this.handleShippingLookupError()

      if (Sentry) {
        Sentry.captureMessage('Error on shipping lookup: ' + JSON.stringify(error))
      }
    }
  }

  async handleShippingLookupError() {
    const request = new FetchRequest("GET", `/shipping_requests/${this.element.dataset.id}/error_partial`, { responseKind: 'html' })
    const response = await request.perform()

    if (response.ok) {
      const responseBody = await response.text
      this.ContentContainerTarget.innerHTML = responseBody
    } else {
      const error = await response.text
      alert(error)
    }
  }

  updateOrderSummaryForShipping(rate) {
    let subTotal = this.SummarySubtotalTarget.innerHTML
    subTotal = subTotal.replace("$", "").replace(",", "")
    subTotal = parseFloat(subTotal)
    const total = Math.round((subTotal + rate) * 100) / 100
    this.SummaryShippingTarget.innerHTML = `$${rate.toFixed(2)}`
    this.SummaryTotalTarget.innerHTML = `$${total.toFixed(2).toLocaleString()}`
  }
}